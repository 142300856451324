import Vue from 'vue';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css'

// // config firebase
// import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"

// const firebaseConfig = {
//   apiKey: "AIzaSyDXWSDbgWmaJW7TQy-klpYUKua-s0bS0Ho",
//   authDomain: "tinytranslator-378802.firebaseapp.com",
//   projectId: "tinytranslator-378802",
//   storageBucket: "tinytranslator-378802.appspot.com",
//   messagingSenderId: "265035187244",
//   appId: "1:265035187244:web:17d9d6f16e83a255087d89",
//   measurementId: "G-FX5GW3H4R4"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
