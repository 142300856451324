<template>
  <v-expansion-panels
    style="position: fixed; bottom: 0px;"
    v-model="pannelIndex"
    tile
  >
<!--     <v-expansion-panel>
      <v-expansion-panel-header class="grey lighten-3" expand-icon="mdi-menu-down">
        <span class='text-h5 font-weight-bold'>Ads</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="grey lighten-4 text-center">
        <p class="text-h4 font-weight-bold">{{adsTitle}}</p>
        <a class="text-h4 font-weight-bold" :href="adsUrl" target="__blank">{{adsUrl}}</a>
      </v-expansion-panel-content>
    </v-expansion-panel> -->

  </v-expansion-panels>
</template>
<script>
export default {
  name: 'BottomAdsBar',
  data: () => ({
    pannelIndex:0,
    adsTitle:'',
    adsUrl:''
  }),
  mounted() {
    let ads=[
      // {
      //   adsTitle:'Unlock a better future with AI',
      //   adsUrl:'https://powerchat.top'
      // },
      // {
      //   adsTitle:'Multi-Language Multi-Engine',
      //   adsUrl:'https://multitranslate.xyz'
      // },
      {
        adsTitle:'PromptBoom:Your All-in-One AI Writing Partner',
        adsUrl:'https://promptboom.com'
      },
    ]
    
    let randomAds=ads[Math.floor(Math.random() * ads.length)]
    this.adsTitle=randomAds['adsTitle']
    this.adsUrl=randomAds['adsUrl']
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.pannelIndex=0
        let randomAds=ads[Math.floor(Math.random() * ads.length)]
        this.adsTitle=randomAds['adsTitle']
        this.adsUrl=randomAds['adsUrl']
      }
    )
  },
}
</script>
