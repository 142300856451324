<template>
  <v-app>
    <v-app-bar app dark>
      <div class="d-flex align-center">
        <router-link to="/" class='text-decoration-none' v-if="$vuetify.breakpoint.smAndDown">
          <v-img alt="ReceiveSMS Logo" class="shrink mr-2" contain src="@/assets/ReceiveSMS.png" transition="scale-transition" width="100" />
        </router-link>
        <router-link to="/" class='text-decoration-none' v-else>
          <v-img alt="ReceiveSMS Logo" class="shrink mr-2" contain src="@/assets/ReceiveSMS.png" transition="scale-transition" width="200" />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <span class="mr-2 text-capitalize">Menu</span>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <router-link v-for="(item, index) in menus" :key="index" :to="item.path" class='text-decoration-none'>
              <v-list-item>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>
      </div>
      <div v-else>
        <router-link :to='item.path' class='text-decoration-none' v-for="(item, index) in menus" :key="index">
          <v-btn text>
            <span class="mr-2 text-capitalize">{{item.name}}</span>
          </v-btn>
        </router-link>
      </div>
    </v-app-bar>
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
    <v-footer dark padless>
        <v-card-text flat tile class="indigo lighten-1 white--text text-center" width="100%">
          Copyright ©{{ new Date().getFullYear() }} —
          <router-link to="/" class='text-decoration-none white--text'>
            <strong>https://receivesms.promptboom.org/</strong>
          </router-link>
        </v-card-text>
    </v-footer>
    <BottomAdsBar />
  </v-app>
</template>
<script>
import BottomAdsBar from '@/components/BottomAdsBar';
export default {
  name: 'App',
  data: () => ({
    menus: [
      {
        'name': 'Home',
        'path': '/'
      },
      {
        'name': 'All Phone Numbers',
        'path': '/All/page/1'
      },
      // {
      //   'name': 'USA',
      //   'path': '/USA'
      // },
      // {
      //   'name': 'Netherlands',
      //   'path': '/Netherlands'
      // },
      // {
      //   'name': 'Finland',
      //   'path': '/Finland'
      // },
      // {
      //   'name': 'Denmark',
      //   'path': '/Denmark'
      // },
      // {
      //   'name': 'UK',
      //   'path': '/UK'
      // },
      // {
      //   'name': 'France',
      //   'path': '/France'
      // },
      // {
      //   'name': 'Belgium',
      //   'path': '/Belgium'
      // },
      // {
      //   'name': 'Sweden',
      //   'path': '/Sweden'
      // },
      {
        'name': 'About',
        'path': '/about'
      },
      {
        'name': 'Terms',
        'path': '/terms'
      },
      {
        'name': 'Contact',
        'path': '/contact'
      }
    ],
  }),
  components: {
    BottomAdsBar
  },
  methods: {
  },
};

</script>
