<template>
  <v-container>
    <!-- Home组件中背景+标题+两处按钮 -->
    <v-container class="d-flex justify-center mt-2 mt-sm-2 mt-md-6 mt-lg-10 mt-xl-10 flex-wrap" v-if="$vuetify.breakpoint.smAndDown">
      <v-img src="../assets/world-map-colortwo.png" height="200" contain style="z-index: 0;position: absolute;"></v-img>
      <v-container style="position: relative; z-index: 1; height: 200px;" class="d-flex justify-center align-center flex-wrap">
        <p class="text-center my-custom-font-title text-h4 text-sm-h3 text-md-h1 text-lg-h1 text-xl-h1 font-weight-black">About us</p>
      </v-container>
    </v-container>
    <v-container class="d-flex justify-center mt-2 mt-sm-2 mt-md-6 mt-lg-10 mt-xl-10 flex-wrap" v-else>
      <v-img src="../assets/world-map-colortwo.png" height="500" contain style="z-index: 0;position: absolute;"></v-img>
      <v-container style="position: relative; z-index: 1; height: 500px;" class="d-flex justify-center align-center flex-wrap">
        <p class="text-center my-custom-font-title text-h4 text-sm-h3 text-md-h1 text-lg-h1 text-xl-h1 font-weight-black">About us</p>
      </v-container>
    </v-container>

    <v-container class="my-10 d-flex flex-wrap align-center text-center">
      <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">
        We offer free phone numbers to everyone, and we continuously add more numbers to our service on a daily basis. Our service is completely free and anonymous, and there is no need to register in order to utilize our temporary phone numbers. These numbers can be used for any purpose, including verifying services such as Google, Twitter, WhatsApp, Facebook, Viber, Line, WeChat, KakaoTalk, and many more. Any SMS messages received will be displayed on our website.
      </p>
      <v-img src="../assets/pic_aboutus.png" height="150" contain v-if="$vuetify.breakpoint.smAndDown"></v-img>
      <v-img src="../assets/pic_aboutus.png" height="300" contain v-else></v-img>
    </v-container>
    <v-divider></v-divider>
    <v-container class="mt-16">
      <h2 class="text-center mb-10">Q&A</h2>
      <v-card v-for="item in QAitems" :key="item.key" class="mx-auto ma-6" width="100%">
        <v-container class="d-flex my-1">
          <v-icon class="mx-2" color="pink darken-4">mdi-chat-question</v-icon>
          <h4 class="ml-4">{{item.question}}</h4>
        </v-container>
        <v-divider inset></v-divider>
        <v-container class="d-flex my-1">
          <v-icon class="mx-2" color="indigo darken-2">mdi-forum</v-icon>
          <p class="ml-4">{{item.answer}}</p>
        </v-container>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: "HomeView",
    data() {
      return {
        QAitems: [
          { 'key': 'key1', 'question': 'What is the purpose of this service?', 'answer': "In today's world, many websites and online services require SMS/phone verification during the sign-up process. However, obtaining another free virtual mobile number for SMS verification can be a hassle. Our free SMS service allows you to skip these steps. You can verify your profile with a real phone number and protect your privacy. Our service can also help you stay safe from scammers by using our phone numbers to receive SMS. You can use our phone number to verify your account on these websites or register on websites that do not support your country."},
          { 'key': 'key2', 'question': 'What is an online SMS receiving service?', 'answer': "An online SMS receiving service is a website that allows you to receive SMS messages using phone numbers provided by the service. There are many such services available on the Internet, some of which are free while others require payment. Our SMS receiving website is completely free of charge and provides you with the phone numbers displayed above for receiving SMS messages. One crucial aspect of an SMS receiving site is that you can only receive messages and not send them."},
          { 'key': 'key3', 'question': 'What is a free virtual phone number or free temporary phone number?', 'answer': "These types of numbers are commonly referred to as DIDs (Direct Inward Dialing) and are telephone numbers that are not directly associated with a physical telephone line. They are typically used to forward incoming calls to another number. A virtual phone number serves as a gateway between a traditional call and VoIP (Voice over IP) services.Other names for a virtual phone number include fake phone number, temporary phone number, and disposable phone number."},
          { 'key': 'key4', 'question': 'What is the price?', 'answer': "Our service is completely free of charge. You can choose a free number from the list above and use it to receive SMS messages without any risks."},
          { 'key': 'key5', 'question': 'Is a mobile phone required to use？', 'answer': "No! All that is needed is an internet browser such as Mozilla Firefox, Chrome, Internet Explorer, or any other compatible browser."},
          { 'key': 'key6', 'question': 'Is it mandatory to register an account to receive SMS？', 'answer': "No! Registration is not required. All phone numbers are provided for free without the need for registration."},
          { 'key': 'key7', 'question': 'How frequently do we add new phone numbers?', 'answer': "We make an effort to add new phone numbers every day, but we ensure that we add them multiple times a week at the very least."}
        ],
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .my-custom-font-title {
    /* font-family: 'Pacifico', cursive; */
    font-family: 'Gasoek One', sans-serif;
    font-size: 100px;
  }
  .my-custom-font-explain {
    font-family: 'Geologica', sans-serif;
  }
</style>
