<template>
  <v-container>
    <!-- Home组件中背景+标题+两处按钮 -->
    <v-container class="d-flex justify-center mt-2 mt-sm-2 mt-md-6 mt-lg-10 mt-xl-10 flex-wrap" v-if="$vuetify.breakpoint.smAndDown">
      <v-img src="../assets/world-map-colortwo.png" height="200" contain style="z-index: 0;position: absolute;"></v-img>
      <v-container style="position: relative; z-index: 1; height: 200px;" class="d-flex justify-center align-center flex-wrap">
        <p class="text-center my-custom-font-title text-h4 text-sm-h3 text-md-h1 text-lg-h1 text-xl-h1 font-weight-black">Why do you need SMS online ?</p>
        <v-container class="d-flex justify-center">
          <v-btn class='mx-auto mr-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 white--text font-weight-black' color="grey darken-4" rounded @click="handleALL">
            <v-icon right dark class="mx-auto mr-4">
              mdi-message-arrow-right
            </v-icon>
            Try Now
          </v-btn>
          <v-btn class='mx-auto ml-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' outlined rounded @click="handleAbout">About us</v-btn>
        </v-container>
      </v-container>
    </v-container>
    <v-container class="d-flex justify-center mt-2 mt-sm-2 mt-md-6 mt-lg-10 mt-xl-10 flex-wrap" v-else>
      <v-img src="../assets/world-map-colortwo.png" height="500" contain style="z-index: 0;position: absolute;"></v-img>
      <v-container style="position: relative; z-index: 1; height: 500px;" class="d-flex justify-center align-center flex-wrap">
        <p class="text-center my-custom-font-title text-h4 text-sm-h3 text-md-h1 text-lg-h1 text-xl-h1 font-weight-black">Why do you need SMS online ?</p>
        <v-container class="d-flex justify-center">
          <v-btn class='mx-auto mr-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 white--text font-weight-black' color="grey darken-4" rounded @click="handleALL">
            <v-icon right dark class="mx-auto mr-4">
              mdi-message-arrow-right
            </v-icon>
            Try Now
          </v-btn>
          <v-btn class='mx-auto ml-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' outlined rounded @click="handleAbout">About us</v-btn>
        </v-container>
      </v-container>
    </v-container>
    <!-- 下部内容：1-隐私，2-免费，3-临时 -->
    <v-container class="my-10 py-5" >
      <v-container class="d-flex justify-center flex-wrap text-center" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="../assets/pic_privacy.png" height="150" contain></v-img>
        <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black mt-2">Your Privacy Is Important</p>
        <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          Are you hesitant to provide your personal phone number to web services, social media platforms, and apps during the sign-up process? Our free SMS service can be the perfect solution for you. With our temporary and virtual numbers, you can easily verify or activate your accounts without the need to share your personal information. We understand that privacy is paramount in today's world, and we want to help you keep your personal information secure while still giving you access to online SMS-activated web services.
        </p>
      </v-container>
      <v-row align="center" no-gutters v-else>
        <v-col cols="6">
          <v-img src="../assets/pic_privacy.png" height="300" contain ></v-img>
        </v-col>
        <v-col cols="6">
          <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Your Privacy Is Important</p>
          <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
            Are you hesitant to provide your personal phone number to web services, social media platforms, and apps during the sign-up process? Our free SMS service can be the perfect solution for you. With our temporary and virtual numbers, you can easily verify or activate your accounts without the need to share your personal information. We understand that privacy is paramount in today's world, and we want to help you keep your personal information secure while still giving you access to online SMS-activated web services.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="my-10 py-5">
      <v-container class="d-flex justify-center flex-wrap text-center" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="../assets/pic_city.png" height="150" contain></v-img>
        <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black mt-2">Free Phone Numbers For Everyone</p>
        <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          Our "Free SMS Receive Service" is and will always be free of charge. We do not charge our users for using our disposable phone numbers. All received messages can be accessed by anyone. You can send as many verification messages as you need to our temporary numbers to activate your accounts, and we do not impose any limits.
        </p>
      </v-container>
      <v-row align="center" no-gutters v-else>
        <v-col cols="5" offset="1">
          <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Free Phone Numbers For Everyone</p>
          <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
            Our "Free SMS Receive Service" is and will always be free of charge. We do not charge our users for using our disposable phone numbers. All received messages can be accessed by anyone. You can send as many verification messages as you need to our temporary numbers to activate your accounts, and we do not impose any limits.
          </p>
        </v-col>
        <v-col cols="6">
          <v-img src="../assets/pic_city.png" height="300" contain ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="my-10 py-5">
      <v-container class="d-flex justify-center flex-wrap text-center" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="../assets/pic_lock.png" height="150" contain></v-img>
        <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black mt-2">Our Phone Numbers Are Temporary</p>
        <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          Messages received through our service are typically available within seconds of being received. Just refresh the page to view them. Please note that the virtual numbers displayed on our website are temporary and disposable. Every month, we provision completely new numbers to ensure your privacy and security. Additionally, SMS messages we receive are permanently deleted after approximately 24 hours to protect your privacy.
        </p>
      </v-container>
      <v-row align="center" no-gutters v-else>
        <v-col cols="6">
          <v-img src="../assets/pic_lock.png" height="300" contain ></v-img>
        </v-col>
        <v-col cols="6">
          <h1 class="my-custom-font-explain">Our Phone Numbers Are Temporary</h1>
          <h4 class="mt-5">
            Messages received through our service are typically available within seconds of being received. Just refresh the page to view them. Please note that the virtual numbers displayed on our website are temporary and disposable. Every month, we provision completely new numbers to ensure your privacy and security. Additionally, SMS messages we receive are permanently deleted after approximately 24 hours to protect your privacy.
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="mt-16">
      <h2 class="text-center mb-10">Q&A</h2>
      <v-card v-for="item in QAitems" :key="item.key" class="mx-auto ma-6" width="100%">
        <v-container class="d-flex my-1">
          <v-icon class="mx-2" color="pink darken-4">mdi-chat-question</v-icon>
          <h4 class="ml-4">{{item.question}}</h4>
        </v-container>
        <v-divider inset></v-divider>
        <v-container class="d-flex my-1">
          <v-icon class="mx-2" color="indigo darken-2">mdi-forum</v-icon>
          <p class="ml-4">{{item.answer}}</p>
        </v-container>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import vuetify from '@/plugins/vuetify';

  export default {
    name: "HomeView",
    data() {
      return {
        QAitems: [
          { 'key': 'key1', 'question': 'What is the purpose of this service?', 'answer': "In today's world, many websites and online services require SMS/phone verification during the sign-up process. However, obtaining another free virtual mobile number for SMS verification can be a hassle. Our free SMS service allows you to skip these steps. You can verify your profile with a real phone number and protect your privacy. Our service can also help you stay safe from scammers by using our phone numbers to receive SMS. You can use our phone number to verify your account on these websites or register on websites that do not support your country."},
          { 'key': 'key2', 'question': 'What is an online SMS receiving service?', 'answer': "An online SMS receiving service is a website that allows you to receive SMS messages using phone numbers provided by the service. There are many such services available on the Internet, some of which are free while others require payment. Our SMS receiving website is completely free of charge and provides you with the phone numbers displayed above for receiving SMS messages. One crucial aspect of an SMS receiving site is that you can only receive messages and not send them."},
          { 'key': 'key3', 'question': 'What is a free virtual phone number or free temporary phone number?', 'answer': "These types of numbers are commonly referred to as DIDs (Direct Inward Dialing) and are telephone numbers that are not directly associated with a physical telephone line. They are typically used to forward incoming calls to another number. A virtual phone number serves as a gateway between a traditional call and VoIP (Voice over IP) services.Other names for a virtual phone number include fake phone number, temporary phone number, and disposable phone number."},
          { 'key': 'key4', 'question': 'What is the price?', 'answer': "Our service is completely free of charge. You can choose a free number from the list above and use it to receive SMS messages without any risks."},
          { 'key': 'key5', 'question': 'Is a mobile phone required to use？', 'answer': "No! All that is needed is an internet browser such as Mozilla Firefox, Chrome, Internet Explorer, or any other compatible browser."},
          { 'key': 'key6', 'question': 'Is it mandatory to register an account to receive SMS？', 'answer': "No! Registration is not required. All phone numbers are provided for free without the need for registration."},
          { 'key': 'key7', 'question': 'How frequently do we add new phone numbers?', 'answer': "We make an effort to add new phone numbers every day, but we ensure that we add them multiple times a week at the very least."}
        ],
      }
    },
    methods: {
      handleALL() {
        // this.$router.replace({
        //   name: 'All'
        // })
        const url='/All/page/1';
        if (this.$route.path !== url) {
          this.$router.push({ path: url});
        }
      },
      handleAbout() {
        // this.$router.replace({
        //   name: 'about'
        // })
        const url='/about';
        if (this.$route.path !== url) {
          this.$router.push({ path: url});
        }
      }
    }
  }
</script>

<style scoped>
  .my-custom-font-title {
    /* font-family: 'Pacifico', cursive; */
    font-family: 'Gasoek One', sans-serif;
  }
  .my-custom-font-explain {
    font-family: 'Geologica', sans-serif;
  }
</style>