import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AllView from '../views/AllView.vue';
import DetailView from '../views/DetailView.vue';
import AboutView from '../views/AboutView.vue';
import ContactView from '../views/ContactView.vue';
import TermsView from '../views/TermsView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta:{
      'title':'Home - ReceiveSMS'
    }
  },
  {
    path: '/All/page/:page',
    name: 'All',
    component: AllView,
    meta:{
      'title':'All Phone Numbers - ReceiveSMS'
    }
  },
  {
    path: '/USA',
    name: 'USA',
    component: HomeView,
    meta:{
      'title':'USA - ReceiveSMS'
    }
  },
  {
    path: '/Netherlands',
    name: 'Netherlands',
    component: HomeView,
    meta:{
      'title':'Netherlands - ReceiveSMS'
    }
  },
  {
    path: '/Finland',
    name: 'Finland',
    component: HomeView,
    meta:{
      'title':'Finland - ReceiveSMS'
    }
  },
  {
    path: '/Denmark',
    name: 'Denmark',
    component: HomeView,
    meta:{
      'title':'Denmark - ReceiveSMS'
    }
  },

  {
    path: '/UK',
    name: 'UK',
    component: HomeView,
    meta:{
      'title':'UK - ReceiveSMS'
    }
  },
  {
    path: '/France',
    name: 'France',
    component: HomeView,
    meta:{
      'title':'France - ReceiveSMS'
    }
  },
  {
    path: '/Belgium',
    name: 'Belgium',
    component: HomeView,
    meta:{
      'title':'Belgium - ReceiveSMS'
    }
  },
  {
    path: '/Sweden',
    name: 'Sweden',
    component: HomeView,
    meta:{
      'title':'Sweden - ReceiveSMS'
    }
  },
  {
    path: '/detail/info/:info',
    component: DetailView,
    meta:{
      'title':'ReceiveSMS - ReceiveSMS'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta:{
      'title':'About - ReceiveSMS'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta:{
      'title':'Contact - ReceiveSMS'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    meta:{
      'title':'Terms - ReceiveSMS'
    }
  },
  {
    path:"*",
    redirect:'/'
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  next()
});
export default router;
