<template>
  <v-container>
    <v-container class="d-flex justify-center mt-2 mt-sm-2 mt-md-6 mt-lg-10 mt-xl-10 flex-wrap" v-if="$vuetify.breakpoint.smAndDown">
      <v-img src="../assets/world-map-colortwo.png" height="200" contain style="z-index: 0;position: absolute;"></v-img>
      <v-container style="position: relative; z-index: 1; height: 200px;" class="d-flex justify-center align-center flex-wrap">
        <p class="text-center my-custom-font-title text-h4 text-sm-h3 text-md-h1 text-lg-h1 text-xl-h1 font-weight-black">Terms of Use & Privacy Policy</p>
      </v-container>
    </v-container>
    <v-container class="d-flex justify-center mt-2 mt-sm-2 mt-md-6 mt-lg-10 mt-xl-10 flex-wrap" v-else>
      <v-img src="../assets/world-map-colortwo.png" height="500" contain style="z-index: 0;position: absolute;"></v-img>
      <v-container style="position: relative; z-index: 1; height: 500px;" class="d-flex justify-center align-center flex-wrap">
        <p class="text-center my-custom-font-title text-h4 text-sm-h3 text-md-h1 text-lg-h1 text-xl-h1 font-weight-black">Terms of Use & Privacy Policy</p>
      </v-container>
    </v-container>

    <p class="text-center my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">
      Welcome to the ReceiveSMS website. These terms and conditions of use ("Agreement") apply to all content, products, and services available through the website. By accessing or using any part of the website, you agree to be bound by this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you may not access the website or use any services. If these terms and conditions are considered an offer by ReceiveSMS, acceptance is expressly limited to these terms.
    </p>

    <v-expansion-panels v-for="item in items" :key="item.header" class="mt-4">
      <v-expansion-panel @change="item.panelsModel=!item.panelsModel">
        <v-expansion-panel-header :color="item.panelsModel? 'blue lighten-2': 'white'">
          <p class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black my-custom-font-explain">{{ item.header }}</p>
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="item.panelsModel? 'blue lighten-2': 'white'">
          {{ item.content }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container class="d-flex justify-center align-center flex-wrap my-4">
      <p class="text-center mt-4 my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">
        Thank you for using ReceiveSMS. If you have any questions or concerns about these terms and conditions or our Privacy Policy, please contact us at:
      </p>
      <div class="email mt-4"> <a class="text-h5" href="mailto:hello@promptboom.org">hello@promptboom.org</a> </div>
    </v-container>

  </v-container>
</template>

<script>
  export default {
    name: "HomeView",
    data() {
      return {
        items: [
          {'panelsModel': false, 'header': 'Use of the Website', 'content':'You may use the website only in accordance with this Agreement and all applicable laws and regulations. ReceiveSMS has the right (though not the obligation) to refuse or remove any content that, in its sole discretion, violates any policy or is in any way harmful or objectionable. ReceiveSMS may also terminate or deny access to the website to any individual or entity for any reason, in its sole discretion. ReceiveSMS will have no obligation to provide a refund of any amounts previously paid.'},
          {'panelsModel': false, 'header': 'Termination', 'content': 'ReceiveSMS may terminate your access to all or any part of the website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your ReceiveSMS account (if you have one), you may simply discontinue using the website. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.'},
          {'panelsModel': false, 'header': 'Disclaimer of Warranties', 'content': 'The website is provided "as is" and without warranties of any kind, express or implied. ReceiveSMS and its suppliers and licensors hereby disclaim all warranties, including, without limitation, the warranties of merchantability, fitness for a particular purpose, and non-infringement. Neither ReceiveSMS nor its suppliers and licensors make any warranty that the website will be error-free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the website at your own discretion and risk.'},
          {'panelsModel': false, 'header': 'Limitation of Liability', 'content': 'In no event will ReceiveSMS or its suppliers be liable with respect to any subject matter of this Agreement under any contract, negligence, strict liability, or other legal or equitable theory for: (i) any special, incidental, or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to ReceiveSMS under this agreement during the twelve (12) month period prior to the cause of action. ReceiveSMS shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.'},
          {'panelsModel': false, 'header': 'General Representation and Warranty', 'content': 'You represent and warrant that your use of the website will be in strict accordance with the ReceiveSMS Privacy Policy, with this Agreement, and with all applicable laws and regulations. You also represent and warrant that your use of the website will not infringe or misappropriate the intellectual property rights of any third party.'},
          {'panelsModel': false, 'header': 'Indemnification', 'content': "You agree to indemnify and hold harmless ReceiveSMS, its contractors, and its licensors, and their respective directors, officers, employees, and agents from and against any and all claims and expenses, including attorneys' fees, arising out of your use of the website, including but not limited to your violation of this Agreement."},
          {'panelsModel': false, 'header': 'Miscellaneous', 'content': "This Agreement constitutes the entire agreement between ReceiveSMS and you concerning the subject matter hereof and may only be modified by a written amendment signed by an authorized executive of ReceiveSMS or by the posting by ReceiveSMS of a revised version. You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions. ReceiveSMS may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors, and permitted assigns."},
          {'panelsModel': false, 'header': 'Privacy Policy', 'content': "ReceiveSMS respects your privacy and is committed to protecting it. We collect non-personally-identifying information from visitors to our website to better understand how they use our website. We may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of our website. We may also collect statistics about the behavior of visitors to our website and may display this information publicly or provide it to others. However, we do not collect or disclose personally-identifying information other than as described in our Privacy Policy. Google is used as an advertisement vendor and employs cookies to serve ads on this site. ReceiveSMS has no access to the cookies used by Google or other advertising vendors for the serving of these advertisements."},
        ]
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .my-custom-font-title {
    /* font-family: 'Pacifico', cursive; */
    font-family: 'Gasoek One', sans-serif;
    font-size: 80px;
  }
  .my-custom-font-explain {
    font-family: 'Geologica', sans-serif;
  }
</style>
